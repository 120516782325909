<template>
    <v-main>
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
    </v-main>
</template>
<script>

export default {
    name: "single-page-layout",
    async mounted() {
        this.appName = process.env.VUE_APP_NAME
        await this.setTheme()
    },
    methods: {
        setTheme(themeSelect) {
            let localTheme = localStorage.getItem('themeDark')
            localTheme = localTheme === 'true' ? true : (localTheme === 'false' ? false : null)
            const mediaTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
            this.$vuetify.theme.dark = (themeSelect !== undefined ? themeSelect : (localTheme !== null ? localTheme : mediaTheme))
            localStorage.setItem('themeDark', this.$vuetify.theme.dark)
            document.documentElement.setAttribute('class', (this.$vuetify.theme.dark ? 'dark' : 'light'))
        }
    }
}
</script>
